import { Outlet} from "react-router-dom";
import cookie from 'js-cookie';
import { logout } from '../../util/auth';
import Navigation from '../Navigation/Navigation';
import Header from "components/Header/Header";


export default function AppShell() {
    const userToken = cookie.get('userToken');
    const user = localStorage.getItem('user');
    // check if a user is found in localStorage and if the userToken cookie is present
    if (!userToken || !user) { 
        logout();
        window.location.href = '/login';
    }

    return ( 
        <div>
            <Navigation />
            <Header />
            <Outlet />
        </div>
);
}