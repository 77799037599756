import Schedule from 'components/Schedule/Schedule';
import Translation from 'components/Translations/translations';

export default function Roster() {
  const { translations, getLocale } = Translation();

  const renderHoursRegistrationContent = () => (
      <>
        <p className='content'>{translations.youWork}</p>
      </>
  );

  return (
    <>
      <Schedule showAllSlots={false} contentRenderer={renderHoursRegistrationContent}/>
    </>
  );
}