import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { api } from '../../util/fetch';
import styles from './Login.module.css';

const apiUrl = process.env.REACT_APP_API_URL;

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('userToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      const expTime = decodedToken.exp * 1000;
      if (Date.now() >= expTime) {
        // Token is expired, redirect to login
        window.location.href = '/login';
        return Promise.reject(new Error('Token expired'));
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    // Sends a POST request to the API to authenticate the user
    axios.post(`${apiUrl}/api/connect/emily`, {
      identifier: username,
      password: password
    })
    .then((response) => {
      const token = response.data.token || response.data.jwt;
      
      // check if a token is present in the response
      if (!token) {
        console.error("No token found in the response.", response.data);
        setError('Er is iets fout gegaan. Probeer het later nog eens.');
        return;
      }

      // decode the token to get the expiration time
      const decodedToken = jwtDecode(token);
      const expTime = new Date(decodedToken.exp * 1000);

      // store the token in a cookie
      Cookies.set('userToken', token, {
        secure: true,
        sameSite: 'Strict',
        expires: expTime
      });

      // get the user data from the API
      api()
        .get("/api/users/me?populate=role")
        .then((response) => {
          const user = response.data;
          // check if the user has a role and if it is an admin then set the isAdmin property
          if (user.role) {
            user.isAdmin = user.role.type === "admin";
            delete user.role;
          }

          localStorage.setItem('user', JSON.stringify(user));

          // redirect the user to the correct page
          if (user.isAdmin) {
            navigate('/admin');
          } else {
            navigate('/roster');
          }
        })
        .catch((error) => {
          console.error(error);
          setError('Onjuiste gebruikersnaam of wachtwoord.');
        });
    })
    .catch((error) => {
      console.error(error);
      setError('Onjuiste gebruikersnaam of wachtwoord.');
    });
  };

  return (
    <div className={styles.login}>
      <form className={styles.loginForm} onSubmit={handleSubmit}>
        <img className={styles.logoP} src='/assets/Login/logo.png' alt='Logo pretore' />
        <p>Gebruikersnaam</p>
        <input
          name='username'
          type="text"
          placeholder="Gebruikersnaam"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <p>Wachtwoord</p>
        <input
          name='password'
          type="password"
          placeholder="Wachtwoord"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className={styles.loginBtn}>Inloggen</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}