import { useState, useEffect } from 'react'
import styles from './Navigation.module.css'
import Translation from 'components/Translations/translations'

export default function Navigation () {
  // Get user from localStorage
  const user = JSON.parse(localStorage.getItem('user'))
  const [showNavigation, setShowNavigation] = useState(false)
  const [darkMode, setDarkMode] = useState(() => {
    // Initialize dark mode state from localStorage
    const savedDarkMode = localStorage.getItem('darkMode')
    return savedDarkMode ? JSON.parse(savedDarkMode) : false
  })
  const { translations } = Translation()

  useEffect(() => {
    // Apply dark mode class to body on loading/refresh
    if (darkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [darkMode])

  const toggleNavigation = () => {
    setShowNavigation(currentShowNavigation => !currentShowNavigation)
  }

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode))
    if (newDarkMode) {
      document.body.classList.add('dark')
      window.location.reload()
    } else {
      document.body.classList.remove('dark')
      window.location.reload()
    }
  }
  const currentPath = window.location.pathname

  return (
    <div>
      <button className={styles.menuIcon} onClick={toggleNavigation}>
        <img
          src='/assets/navigation/bars-solid.svg'
          alt='Menu Icon'
          className={`${styles.menuIcon} ${
            darkMode ? styles.menuIconDark : ''
          }`}
        />
      </button>

      <div
        className={`${styles.navigation} ${showNavigation ? styles.show : ''}`}
      >
        <div className={styles.sideNav}>
          <button className={styles.closeNavigation} onClick={toggleNavigation}>
            <img
              className={`${styles.closeNavigation} ${
                darkMode ? styles.closeNavigationDark : ''
              }`}
              src='/assets/navigation/close.svg'
              alt='X'
            />
          </button>
          <div className={styles.headerLogo}>
            <img
              src={darkMode ? '/assets/Logo-dark-mode.svg' : '/assets/Logo-Pretore.svg'}
              alt='Logo Pretore'
            />
          </div>
          <ul>
            <li>
              <p>
                <button
                  onClick={toggleDarkMode}
                  className={`${styles.darkModeSwitch}`}
                >
                  {darkMode ? `🌙 ${translations.dark}` : `🔆 ${translations.light}`}
                </button>
              </p>
            </li>
            {user.isAdmin && (
              <li className={currentPath === '/admin' ? styles.active : ''}>
                <a href='/admin'>
                  <img
                    src='/assets/navigation/admin.png'
                    alt='Admin Icon'
                    className={`${styles.adminImg} ${
                      darkMode ? '' : styles.iconDark
                    }`}
                  />
                  <span>{translations.admin}</span>
                </a>
              </li>
            )}

            <li className={currentPath === '/roster' ? styles.active : ''}>
              <a href='/roster'>
                <img
                  src='/assets/navigation/roster.png'
                  alt='Roster Icon'
                  className={`${styles.rosterImg} ${
                    darkMode ? '' : styles.iconDark
                  }`}
                />
                <span>{translations.roster}</span>
              </a>
            </li>
            <li
              className={
                currentPath === '/roster/register' ? styles.active : ''
              }
            >
              <a href='/roster/register' className={styles.navLink}>
                <img
                  src='/assets/navigation/openhours.png'
                  alt='Roster Icon'
                  className={`${styles.rosterImg} ${
                    darkMode ? '' : styles.iconDark
                  }`}
                />
                <span>{translations.openHours}</span>
              </a>
            </li>
            <li>
              <a
                href='#'
                onClick={e => {
                  e.preventDefault()
                  if (window.confirm(translations.confirmLogout)) {
                    window.location.href = '/logout'
                  }
                }}
              >
                <img
                  src='/assets/navigation/logout.png'
                  alt='Logout Icon'
                  className={`${styles.logoutImg} ${
                    darkMode ? '' : styles.iconDark
                  }`}
                />
                <span>{translations.logout}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
