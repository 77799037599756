import { useState } from 'react';
import Schedule from 'components/Schedule/Schedule';
import { api } from 'util/fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Translation from 'components/Translations/translations';

export default function HoursRegistration() {
  const [signingUpItems, setSigningUpItems] = useState([]);
  const [toastShown, setToastShown] = useState(false); 
  const [infoDay, setInfoDay] = useState(null);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const { translations } = Translation();


  //all the toast messages for on this page\\
  function Msg({ uid }){
    return <span>{uid}</span>;
  }

  const registerToast = () => {
    toast.success(<Msg uid={"Je bent aangemeld voor dit tijdstip."} />, {
      onClose: () => window.location.reload()
    });
  };

  const registerToastError = () => {
    toast.error(<Msg uid={"Je bent al aangemeld voor dit tijdstip."} />, {
      onClose: () => window.location.reload()
    });
  };

  const fullToast = () => {
    toast.error(<Msg uid={"Helaas, er zijn geen plaatsen meer over voor dit tijdstip."} />, {
      onClose: () => window.location.reload()
    });
  };

  // Function to sign up for a time slot\\
  const handleSignUp = (item) => {
    if (signingUpItems.includes(item.id)) return; 
    setSigningUpItems((prev) => [...prev, item.id]);

    if (item.users.length < item.numberOfEmployees) {
      api()
        .post(`/api/available-work/${item.id}/register`, { userId })
        .then(() => {
          if (!toastShown) {
            registerToast();
            setToastShown(true);
            setTimeout(() => setToastShown(false), 2000);
          }
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            if (!toastShown) {
              registerToastError();
              setToastShown(true);
              setTimeout(() => setToastShown(false), 3000);
            }
          }
        })
        .finally(() => {
          setSigningUpItems((prev) => prev.filter((id) => id !== item.id));
        });
    } else {
      if (!toastShown) {
        fullToast();
        setToastShown(true);
        setTimeout(() => setToastShown(false), 3000);
      }
      setSigningUpItems((prev) => prev.filter((id) => id !== item.id));
    }
  };

  const renderHoursRegistrationContent = (item) => (
    <>
      <p className="content">{item.users.length}/{item.numberOfEmployees} {translations.registerd}</p>
    <button className="register" onClick={() =>  handleSignUp(item) & setInfoDay(null) } disabled={signingUpItems.includes(item.id)}>
      {translations.toRegister} {signingUpItems.includes(item.id)}
    </button>
    </>

  );

  return (
    <>
      <Schedule contentRenderer={renderHoursRegistrationContent} showAllSlots={true} />
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover={false}
        theme="dark"
        limit={1}
      />
    </>
  );
}