import { useState, useEffect } from 'react';
import { nl, enUS, pl, de, sv } from "date-fns/locale";

export default function Translation() {
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'nl');

  useEffect(() => {
    // Load translations based on the selected language
    import(`../../locales/${language}.json`).then((module) => {
      setTranslations(module.default);
    }).catch((error) => {
      console.error('Error loading translations:', error);
    });
  }, [language]);

  const getLocale = () => {
    switch (language) {
      case 'en':
        return enUS;
      case 'pl':
        return pl;
      case 'de':
        return de;
      case 'sv':
        return sv;
      default:
        return nl;
    }
  };

  return { translations, getLocale };
}