import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from 'components/Login/Login';
import AppShell from "components/AppShell/AppShell";
import Roster from 'components/Roster/Roster';
import Logout from "components/Pages/Logout";
import Admin from 'components/Pages/Admin';
import IsAdmin from 'components/AppShell/IsAdmin'; 
import HoursRegistration from 'components/Pages/HoursRegistration';
import NotFound from 'components/404'; 

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/' element={<AppShell />}>
          <Route path='/roster' element={<Roster />} />
          <Route path='/roster/register' element={<HoursRegistration />} />   
          <Route element={<IsAdmin />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} /> 
      </Routes>
    </Router>
  );
}