import { useState, useEffect } from 'react';
import { api } from 'util/fetch';
import { toast, ToastContainer } from 'react-toastify';
import { format, getDay } from 'date-fns';
import Translation from 'components/Translations/translations';
import Schedule from 'components/Schedule/Schedule';
import 'react-toastify/dist/ReactToastify.css';

export default function Admin() {
  const [newWork, setNewWork] = useState({ date: '', shift: '', numberOfEmployees: 0, startTime: '', endTime: '' });
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [isSaturday, setIsSaturday] = useState(false);
  const [infoDay, setInfoDay] = useState(null);
  const [data, setData] = useState([]);
  const { translations } = Translation();

  useEffect(() => {
    if (selectedDate) {
      const date = new Date(selectedDate);
      setIsSaturday(getDay(date) === 6);
    }
  }, [selectedDate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWork((prev) => ({ ...prev, [name]: value }));
    if (name === 'date') {
      setSelectedDate(value);
    }
  };

  const formatTime = (time) => {
    return time.length === 5 ? `${time}:00.000` : time;
  };

  //all the toast messages for on this page\\
  function Msg({ uid }){
    return <span>{uid}</span>;
  }

  const createToast = () => {
    toast.success(<Msg uid={"uur sucssesvol aangemaakt"} />, {
      onClose: () => window.location.reload()
    });
  };

  const modifyToast = () => {
    toast.success(<Msg uid={"uur sucssesvol aangepast"} />, {
      onClose: () => window.location.reload()
    });
  };

  const delateToast = () => {
    toast.success(<Msg uid={"uur sucssesvol verwijderd"} />, {
      onClose: () => window.location.reload()
    });
  };

// Get start and end time based on shift\\
  const createWork = (e) => {
    e.preventDefault();

    let startTime = '';
    let endTime = '';

    if (isSaturday) {
      switch (newWork.shift) {
        case 'morning':
          startTime = '09:00:00.000';
          endTime = '13:00:00.000';
          break;
        case 'afternoon':
          startTime = '13:00:00.000';
          endTime = '17:00:00.000';
          break;
        case 'aangepast':
          startTime = formatTime(newWork.startTime);
          endTime = formatTime(newWork.endTime);
          break;
        default:
          console.error('Onbekende shift');
          return;
      }
    } else {
      switch (newWork.shift) {
        case 'morning':
          startTime = '08:00:00.000';
          endTime = '12:30:00.000';
          break;
        case 'afternoon':
          startTime = '12:30:00.000';
          endTime = '16:45:00.000';
          break;
        case 'evening':
          startTime = '18:00:00.000';
          endTime = '21:00:00.000';
          break;
        case 'aangepast':
          startTime = formatTime(newWork.startTime);
          endTime = formatTime(newWork.endTime);
          break;
        default:
          console.error('Onbekende shift');
          return;
      }
    }

    if (newWork.shift === 'aangepast' && (!startTime || !endTime)) {
      toast.error(translations.errorTimeRequired);
      return;
    }

    const formattedDate = format(
      new Date(newWork.date || selectedDate), 
      'yyyy-MM-dd'
    );

    // api call to create work\\
    const workToCreate = {
      data: {
        date: formattedDate,
        startTime,
        endTime,
        numberOfEmployees: parseInt(newWork.numberOfEmployees, 10)
      }
    };

    api()
      .post('api/available-works', workToCreate, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        createToast();
        setNewWork({ date: '', shift: '', numberOfEmployees: 0, startTime: '', endTime: '' });
      })
      .catch((error) => {
        console.error('Error creating work:', error);
        toast.error('Fout bij het versturen');
      });
  };

  //if there is no item found on that day to change, then show this message\\
  const renderAdminChange = (selectedDate, selectedShift, item) => {
    if (!item || !item.id) {
      return (
        <div className='contentWrapper'>
          <p>Geen data beschikbaar om te bewerken.</p>
          <img className='closeBtn' src='/assets/x.svg' alt='Close' onClick={() => setInfoDay(null)}/>
        </div>
      );
    }

    // Function to update a work slot\\
    const handleUpdate = (e) => {
      e.preventDefault();

      const updatedData = {
        date: e.target.date.value || item.date,
        startTime: formatTime(e.target.startTime.value) || item.startTime,
        endTime: formatTime(e.target.endTime.value) || item.endTime,
        numberOfEmployees: parseInt(e.target.numberOfEmployees.value, 10) || item.numberOfEmployees,
      };

      const payload = {
        data: updatedData,
      };

      api()
        .put(`api/available-works/${item.id}`, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          modifyToast();
        })
        .catch((error) => {
          console.error('Error bij het aanpassen van werk:', error);
          toast.error('Fout bij het aanpassen van werk');
        });
    };

    // Function to delete a work slot\\
    const handleDelete = () => {
      if (window.confirm(translations.confirmDelete)) {
        api().delete(`/api/available-works/${item.id}`)
          .then(() => {
           delateToast();
          })
          .catch((error) => {
            console.error("Er is iets fout gegaan bij het verwijderen van de dienst", error);
            toast.error('Fout bij het verwijderen');
          });
      }
    };
    
    return (
      <div className='changeOrDelete'>
        <form className="formAdmin" onSubmit={handleUpdate}>
          <h3>{translations.modifyOrDeleteShift}</h3>
          <label>{translations.date}</label>
          <input type="date" name="date" defaultValue={item.date} />
          <br />
          <label>{translations.startTime}</label>
          <input type="time" name="startTime" defaultValue={item.startTime} />
          <br />
          <label>{translations.endTime}</label>
          <input type="time" name="endTime" defaultValue={item.endTime} />
          <br />
          <label>{translations.numberOfEmployees}</label>
          <input type="number" min={1} name="numberOfEmployees" defaultValue={item.numberOfEmployees} />
          <br />
          <button type="submit" className='change'>{translations.modify}</button>
          <button type="button" className='delete' onClick={handleDelete}>
            {translations.delete}
          </button>
        </form>
      </div>
    );
  };
  
//Function to render the admin content for the places that don't have any data\\
  const renderAdminContent = () => (
    <form className='formAdmin' onSubmit={createWork}>
      <label>{translations.date}</label>
      <input type='date' name='date' value={newWork.date || selectedDate} onChange={handleInputChange} required/>
      <br />
      <label>{translations.shift}</label>
      <select name='shift' value={newWork.shift || selectedShift} onChange={handleInputChange} required>
        <option value=''>{translations.selectTime}</option>
        <option value='morning'>
          {isSaturday ? translations.morningSaturday : translations.morningSelect}
        </option>
        <option value='afternoon'>
          {isSaturday ? translations.afternoonSaturday : translations.afternoonSelect}
        </option>
        {!isSaturday && (
          <option value='evening'>{translations.eveningSelect}</option>
        )}
        <option value='aangepast'>{translations.custom}</option>
      </select>
      {newWork.shift === 'aangepast' && (
        <>
          <label>{translations.startTime}</label>
          <input type='time' name='startTime' value={newWork.startTime || ''} onChange={handleInputChange} required />
          <label>{translations.endTime}</label>
          <input type='time' name='endTime' value={newWork.endTime || ''} onChange={handleInputChange} required />
        </>
      )}
      <br />
      <label>{translations.numberOfEmployees}</label>
      <input type='number' min={1} name='numberOfEmployees' value={newWork.numberOfEmployees} onChange={handleInputChange} required />
      <button type='submit'>{translations.save}</button>
    </form>
  );

  return (
    <div>
      <Schedule
        isAdmin={true}
        showAllSlots={true}
        renderAdminContent={renderAdminContent}
        renderAdminChange={renderAdminChange}
        setSelectedDate={setSelectedDate}
        setSelectedShift={setSelectedShift}
      />
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover={false}
        theme="dark"
        limit={1}
      />
    </div>
  );
}