import axios from "axios";
import Cookies from "js-cookie";

export function api() {
    const token = Cookies.get('userToken');
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
}