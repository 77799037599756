import { useState, useEffect } from 'react';
import { api } from './fetch';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

// Categorize shifts into morning, afternoon, and evening
export function categorizeShifts(dayData, isSaturday = false) {
  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return { hours, minutes, seconds };
  };

  const isWithinTimeRange = (startTime, endTime, targetTime) => {
    const start = parseTime(startTime);
    const end = parseTime(endTime);
    const target = parseTime(targetTime);

    const startTotalMinutes = start.hours * 60 + start.minutes;
    const endTotalMinutes = end.hours * 60 + end.minutes;
    const targetTotalMinutes = target.hours * 60 + target.minutes;

    return targetTotalMinutes >= startTotalMinutes && targetTotalMinutes < endTotalMinutes;
  };

  if (isSaturday) {
    const morning = dayData.filter(item => isWithinTimeRange('09:00:00', '13:00:00', item.startTime));
    const afternoon = dayData.filter(item => isWithinTimeRange('13:00:00', '17:00:00', item.startTime));
    return { morning, afternoon, evening: [] };
  }

  const morning = dayData.filter(item => isWithinTimeRange('08:00:00', '12:30:00', item.startTime));
  const afternoon = dayData.filter(item => isWithinTimeRange('12:30:00', '16:45:00', item.startTime));
  const evening = dayData.filter(item => isWithinTimeRange('18:00:00', '21:00:00', item.startTime));

  return { morning, afternoon, evening };
}

// Hook to fetch roster data
export function useRoster() {
  const [data, setData] = useState([]);

  const fetchData = () => {
    api()
      .get('api/available-works?populate=users&pagination[pageSize]=10000')
      .then(response => {
        const responseData = Array.isArray(response.data) ? response.data : response.data.data || [];
        setData(responseData);
      })
      .catch(error => {
        toast.error('Failed to fetch data. Please try again later.');
        console.error(error);
        setData([]);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getDataForDayUser = (day) => {
    if (!Array.isArray(data)) return [];
    const dayString = format(day, 'yyyy-MM-dd'); 
    return data.filter(item => item.date === dayString);
  };

  return { getDataForDayUser, fetchData };
}
