import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/404.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Pagina niet gevonden</h2>
      <p>Sorry, de pagina die je zoekt bestaat niet.</p>
      <Link to="/">Ga terug naar de homepagina</Link>
      <button onClick={() => window.history.back()}>Ga terug</button>
    </div>
  );
};

export default NotFound;