import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'util/auth';

// This component logs the user out and redirects to the login page
export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate('/login');
    },[navigate]);

    return null;
}