import { useEffect, useState } from 'react';
import styles from './header.module.css';
import 'styles/index.css';
import Translation from 'components/Translations/translations';

export default function Header() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'nl');

  const { translations} = Translation();
  translations.confirmLogout = undefined;

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
    const savedDarkMode = JSON.parse(localStorage.getItem('darkMode'));
    if (savedDarkMode) {
      setDarkMode(savedDarkMode);
      document.body.classList.add('dark');
    }
  }, []);
  const handleLanguageChange = e => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    window.location.reload();
  };

  return (
    <header className={styles.header}>
      <p className={styles.path}>
        <bold>Pretore</bold>
        <img
          src='/assets/chevron.svg'
          className={styles.chevron}
          style={{ filter: darkMode ? 'none' : 'invert(1)' }}
          alt={''}/>
        {window.location.pathname.substring(1)}
      </p>
      <div className={styles.userNameContainer}>
      <div className={styles.userNameContainer}>
        <select
          className={styles.languageSelect}
          value={language}
          onChange={handleLanguageChange}
        >
          <option value='nl'>NL</option>
          <option value='en'>EN</option>
          <option value='pl'>PL</option>
          <option value='de'>DE</option>
          <option value='sv'>SV</option>
        </select>
        <div className={styles.icon}><p>{user.username.charAt(0)}</p></div>
        <div
          className={`${styles.userName} ${
            darkMode ? styles.userNameDark : styles.userNameLight
          }`}
        >
          <div>{user.username}</div>
          <div><span className={styles.email}>{user.email}</span></div>
        </div>
        </div>
        <img
          className={styles.logoutIcon}
          onClick={e => {
            e.preventDefault();
            if (window.confirm(translations.confirmLogout)) {
              window.location.href = '/logout';
            }
          }}
          src='/assets/navigation/logout.svg'
          alt='Logout Icon'
          style={{ filter: darkMode ? 'none' : 'invert(1)' }}
        />
      </div>
    </header>
  );
}