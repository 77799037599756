import { addWeeks, subWeeks, startOfWeek, eachDayOfInterval, format, getISOWeek, getDay } from 'date-fns';
import { useRoster, categorizeShifts } from "util/roster";
import { useEffect, useState, useRef } from 'react';
import Translation from 'components/Translations/translations';
import './schedule.module.css';
import 'styles/index.css';

export default function Schedule({
  contentRenderer,
  renderName,
  showAllSlots = false,
  isAdmin = false,
  renderAdminContent,
  renderAdminChange 
}) {
  const { getDataForDayUser } = useRoster();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState([]);
  const [infoDay, setInfoDay] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const infoRef = useRef(null);

  const { translations, getLocale } = Translation();

  // Gets start of the current week and sets the current week
  useEffect(
    () => {
      const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
      const daysOfWeek = eachDayOfInterval({
        start: startOfCurrentWeek,
        end: addWeeks(startOfCurrentWeek, 1)
      });
      setCurrentWeek(daysOfWeek.slice(0, 6));
    },
    [currentDate]
  );

  const generateSlotId = (date, period, index) => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return `${dateObj.toISOString().split('T')[0]}-${period}-${index}`;
  };

  const handleNextWeek = () => setCurrentDate(addWeeks(currentDate, 1));
  const thisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const handlePrevWeek = () => setCurrentDate(subWeeks(currentDate, 1));

  const openAdminForm = (date, period) => {
    if (isAdmin) {
      setInfoDay(date);
      setSelectedDate(format(date, 'yyyy-MM-dd'));
      setSelectedShift(period);
    }
  };

  const getBackgroundColor = (numberOfEmployees, openPlaces) => {
    if (openPlaces === 0 && (window.location.pathname === '/admin' || window.location.pathname === '/roster/register')) {
      return '#E57373';
    } else if (numberOfEmployees < 3 && (window.location.pathname === '/admin' || window.location.pathname === '/roster/register')) {
      return '#F2A65A';
    } else if (numberOfEmployees >= 3 && (window.location.pathname === '/admin' || window.location.pathname === '/roster/register')) {
      return '#81C784';
    } else if (window.location.pathname === '/roster') {
      return '';
    }
  };

  const calculateAvailableSpots = (shift) => {
    const totalEmployees = shift.numberOfEmployees || 0;
    const bookedEmployees = shift.users ? shift.users.length : 0;
    return totalEmployees - bookedEmployees;
  };

  const WeekNavigation = () => (
    <div className="weekNavigation">
      <button className="btn back" onClick={handlePrevWeek}>
        {translations.previousWeek}
      </button>
      <button className='btn today' onClick={() => setCurrentDate(thisWeek)}>
        {translations.thisWeek}
      </button>
      <button className="btn next" onClick={handleNextWeek}>
        {translations.nextWeek}
      </button>
    </div>
  );

  const renderTimeSlots = (slots, period, date) => {
    return slots.length > 0
      ? slots.map((item, index) => {
          const openPlaces = calculateAvailableSpots(item);
          return (
            <div
              key={generateSlotId(date, period, index)}
              onClick={e => {
                e.stopPropagation();
                setInfoDay(generateSlotId(date, period, index));
              }}
              className="time"
              style={{ backgroundColor: getBackgroundColor(item.numberOfEmployees, openPlaces) }}
            >
            <p className={window.location.pathname === '/roster' ? 'startEndTimeRoster' : 'startEndTime'}>
              {item.startTime.slice(0, 5)} - {item.endTime.slice(0, 5)}
            </p>
            {window.location.pathname !== '/roster' && (
            <p className='openPlaces'>
              {openPlaces} {translations.openPlaces}
            </p>
            )}
              {infoDay === generateSlotId(date, period, index) && (
                <div ref={infoRef} className="extraInfo">
                  <div className="contentWrapper">
                    <img
                      className="closeBtn"
                      src="/assets/Admin/x.svg"
                      alt="Close"
                      onClick={e => {
                        e.stopPropagation();
                        setInfoDay(null);
                      }}
                    />
                    {isAdmin
                      ? renderAdminChange(selectedDate, selectedShift, item)
                      : contentRenderer ? contentRenderer(item, period) : null}
                  </div>
                </div>
              )}
            </div>
          );
        })
      : isAdmin &&
        infoDay === date &&
        selectedShift === period && (
          <div ref={infoRef} className="extraInfo">
            <div className="contentWrapper">
              <img
                className="closeBtn"
                src="/assets/Admin/x.svg"
                alt="Close"
                onClick={e => {
                  e.stopPropagation();
                  setInfoDay(null);
                  console.log("Close button clicked (empty slot)");
                }}
              />
              {renderAdminContent
                ? renderAdminContent(selectedDate, selectedShift)
                : null}
            </div>
          </div>
        );
  };

  const DayRow = ({ date }) => {
    const isSaturday = getDay(date) === 6;
    const dayData = getDataForDayUser(date);
    const { morning, afternoon, evening } = categorizeShifts(dayData, isSaturday);
    const locale = getLocale();

    const filterSlots = (slots) =>
      showAllSlots
        ? slots
        : slots.filter((item) =>
            item.users.some(
              (user) => user.id === JSON.parse(localStorage.getItem('user'))?.id
            )
          );

    return (
      <tr style={{ backgroundColor: isSaturday ? '#0089b440' : '' }}>
        <td className="days">
          <span className="fullDayName">{format(date, 'EEEE d MMMM', { locale })}</span>
          <span className="shortDayName">{format(date, 'EEE d MMM', { locale })}</span>
        </td>
        <td
          className="times"
          style={{ backgroundColor: morning.length > 0 ? getBackgroundColor(morning[0].numberOfEmployees, calculateAvailableSpots(morning[0])) : '' }}
          onClick={() => openAdminForm(date, 'morning')}
        >
          {renderTimeSlots(filterSlots(morning), 'morning', date)}
        </td>
        <td
          className="times"
          style={{ backgroundColor: afternoon.length > 0 ? getBackgroundColor(afternoon[0].numberOfEmployees, calculateAvailableSpots(afternoon[0])) : '' }}
          onClick={() => openAdminForm(date, 'afternoon')}
        >
          {renderTimeSlots(filterSlots(afternoon), 'afternoon', date)}
        </td>
          <td
            className="times"
            style={{ backgroundColor: evening.length > 0 ? getBackgroundColor(evening[0].numberOfEmployees, calculateAvailableSpots(evening[0])) : '' }}
            onClick={() => openAdminForm(date, 'evening')}
          >
            {renderTimeSlots(filterSlots(evening), 'evening', date)}
          </td>
      </tr>
    );
  };

  return (
    <div className="rosterContainer">
      <h1 className="admin">
      {renderName ? renderName() : translations.roster} {translations.week} {getISOWeek(currentDate)}
      </h1>
      <table className="rosterTable">
        <thead>
          <tr className="topHeader">
          <th className="topHeaderDay">{translations.day}</th>
            <th>{translations.morning}</th>
            <th>{translations.afternoon}</th>
            <th>{translations.evening}</th>
          </tr>
        </thead>
        <tbody>
          {currentWeek.map(date =>
            <DayRow key={date.toISOString()} date={date} />
          )}
        </tbody>
      </table>
      <WeekNavigation />
    </div>
  );
}