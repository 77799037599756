import { Outlet } from 'react-router-dom';
import { logout } from 'util/auth';

export default function IsAdmin() {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;
    //checks if the user is an admin
    if (!user || !user.isAdmin) {
        logout();
        window.location.href = '/login';
    }
  
    return (
            <Outlet/>
    );
}